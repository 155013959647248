import React from 'react';
import './Pricing.css';

interface PricingTier {
  id: number;
  name: string;
  price: string;
  description: string;
  features: string[];
  isPopular?: boolean;
}

const pricingTiers: PricingTier[] = [
  {
    id: 1,
    name: 'Starter',
    price: '$29',
    description: 'Perfect for small projects and individual developers',
    features: [
      'Up to 3 projects',
      '5GB storage',
      'Basic analytics',
      'Community support',
      'Basic CI/CD pipeline'
    ]
  },
  {
    id: 2,
    name: 'Pro',
    price: '$99',
    description: 'Ideal for growing teams and businesses',
    features: [
      'Unlimited projects',
      '50GB storage',
      'Advanced analytics',
      'Priority support',
      'Advanced CI/CD pipeline',
      'Custom domains'
    ],
    isPopular: true
  },
  {
    id: 3,
    name: 'Enterprise',
    price: 'Custom',
    description: 'For large organizations with specific needs',
    features: [
      'Unlimited everything',
      'Dedicated support',
      'Custom integrations',
      'SLA guarantee',
      'Advanced security',
      'Team training'
    ]
  }
];

const Pricing: React.FC = () => {
  return (
    <div className="pricing-section">
      <div className="pricing-container">
        <div className="pricing-header">
          <h2>Simple, Transparent Pricing</h2>
          <p>Choose the perfect plan for your needs</p>
        </div>

        <div className="pricing-grid">
          {pricingTiers.map((tier) => (
            <div 
              key={tier.id} 
              className={`pricing-card ${tier.isPopular ? 'popular' : ''}`}
            >
              {tier.isPopular && <div className="popular-badge">Most Popular</div>}
              <div className="pricing-content">
                <h3>{tier.name}</h3>
                <div className="price">
                  <span className="amount">{tier.price}</span>
                  {tier.price !== 'Custom' && <span className="period">/month</span>}
                </div>
                <p className="description">{tier.description}</p>
                <ul className="features-list">
                  {tier.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <button className="get-started">Get Started</button>
              </div>
              <div className="pricing-glow"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
