import React from 'react';
import { Link } from 'react-router-dom';
import './Hero.css';

const Hero = () => {
  return (
    <div className="hero-section">
      <div className="particle-container">
        {[...Array(20)].map((_, i) => (
          <div key={i} className="particle"></div>
        ))}
      </div>
      
      <div className="hero-content">
        <h1 className="hero-title">
          <span className="gradient-text">Ready-Made Apps</span>
          <br />
          Powered by AWS Global Infrastructure
        </h1>
        
        <p className="hero-description">
          Launch your application instantly with our pre-built templates, backed by AWS's
          worldwide network. Start with production-ready apps and scale globally without the setup hassle.
        </p>

        <div className="hero-stats">
          <div className="stat-item">
            <span className="stat-number">600+</span>
            <span className="stat-label">CloudFront POPs</span>
          </div>
          <div className="stat-item">
            <span className="stat-number">108</span>
            <span className="stat-label">Availability Zones</span>
          </div>
          <div className="stat-item">
            <span className="stat-number">34</span>
            <span className="stat-label">Global Regions</span>
          </div>
          <div className="stat-item">
            <span className="stat-number">13</span>
            <span className="stat-label">Edge Caches</span>
          </div>
        </div>

        <div className="hero-cta">
          <Link to="/templates" className="cta-button primary">
            Explore Solutions
            <span className="button-glow"></span>
          </Link>
          <Link to="/contact" className="cta-button secondary">
            Contact Sales
            <span className="button-glow"></span>
          </Link>
        </div>

        <div className="tech-stack">
          <div className="tech-title">Powered by</div>
          <div className="tech-icons">
            <div className="tech-icon">React</div>
            <div className="tech-icon">Node.js</div>
            <div className="tech-icon">ECS</div>
            <div className="tech-icon">Fargate</div>
            <div className="tech-icon">EKS</div>
            <div className="tech-icon">CloudFront</div>
          </div>
        </div>
      </div>

      <div className="hero-background">
        <div className="gradient-sphere"></div>
        <div className="grid-overlay"></div>
      </div>
    </div>
  );
};

export default Hero;
