import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
      <Link to="/" className="logo">
        <div className="logo-container">
          <span className="logo-text">
            <span className="logo-bne">BNE</span>
            <span className="logo-space">SPACE</span>
          </span>
          <div className="logo-glow"></div>
        </div>
      </Link>

      <button 
        className="md:hidden flex flex-col justify-center items-center w-8 h-8"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        aria-label="Toggle menu"
      >
        <span className={`hamburger-bar ${isMobileMenuOpen ? 'open' : ''}`}></span>
        <span className={`hamburger-bar ${isMobileMenuOpen ? 'open' : ''}`}></span>
        <span className={`hamburger-bar ${isMobileMenuOpen ? 'open' : ''}`}></span>
      </button>

      <nav className={`main-nav ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
        <ul>
          <li><Link to="/features" className="nav-link">Features</Link></li>
          <li><Link to="/benefits" className="nav-link">Benefits</Link></li>
          <li><Link to="/pricing" className="nav-link">Pricing</Link></li>
          <li><Link to="/templates" className="nav-link">Templates</Link></li>
          <li><Link to="/contact" className="nav-link">Contact</Link></li>
        </ul>
      </nav>

      <button className="cta-button hidden md:block">
        Get Started
      </button>
    </header>
  );
};

export default Header;
