import React from 'react';
import './Benefits.css';

interface Benefit {
  id: number;
  title: string;
  description: string;
  metric?: string;
}

const benefits: Benefit[] = [
  {
    id: 1,
    title: 'Faster Development',
    description: 'Reduce development time by up to 70% with our streamlined deployment process',
    metric: '70% Faster'
  },
  {
    id: 2,
    title: 'Cost Efficient',
    description: 'Save up to 60% on infrastructure costs with our optimized cloud solutions',
    metric: '60% Savings'
  },
  {
    id: 3,
    title: 'Enhanced Security',
    description: 'Enterprise-grade security protocols protecting your applications 24/7',
    metric: '99.9% Uptime'
  },
  {
    id: 4,
    title: 'Global Scale',
    description: 'Deploy your applications across multiple regions with a single click',
    metric: '200+ Regions'
  }
];

const Benefits: React.FC = () => {
  return (
    <div className="benefits-section">
      <div className="benefits-container">
        <div className="benefits-header">
          <h2>Why Choose Us</h2>
          <p>Transform your development workflow with our cutting-edge platform</p>
        </div>

        <div className="benefits-grid">
          {benefits.map((benefit) => (
            <div key={benefit.id} className="benefit-card">
              <div className="benefit-content">
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
                {benefit.metric && (
                  <div className="metric">
                    <span>{benefit.metric}</span>
                  </div>
                )}
              </div>
              <div className="benefit-glow"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
