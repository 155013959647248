import React from 'react';
import './Features.css';

interface Feature {
  id: number;
  icon: string;
  title: string;
  description: string;
}

const features: Feature[] = [
  {
    id: 1,
    icon: '🚀',
    title: 'Instant Deployment',
    description: 'Deploy your applications in seconds with our automated CI/CD pipeline'
  },
  {
    id: 2,
    icon: '🛡️',
    title: 'Advanced Security',
    description: 'Enterprise-grade security with SSL certificates and DDoS protection'
  },
  {
    id: 3,
    icon: '⚡',
    title: 'High Performance',
    description: 'Optimized infrastructure ensuring lightning-fast load times'
  },
  {
    id: 4,
    icon: '🔄',
    title: 'Auto Scaling',
    description: 'Dynamic resource allocation based on your application needs'
  },
  {
    id: 5,
    icon: '📊',
    title: 'Real-time Analytics',
    description: 'Comprehensive insights into your application performance'
  },
  {
    id: 6,
    icon: '🔧',
    title: 'Easy Management',
    description: 'Intuitive dashboard for seamless application management'
  }
];

const Features: React.FC = () => {
  return (
    <section className="features-section">
      <div className="features-container">
        <div className="section-header">
          <h2>Powerful Features</h2>
          <p>Everything you need to deploy and scale your applications</p>
        </div>
        
        <div className="features-grid">
          {features.map((feature) => (
            <div key={feature.id} className="feature-card">
              <div className="feature-icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
              <div className="feature-hover-effect"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
