import React from 'react';
import './TemplateShowcase.css';

interface Template {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  demoUrl: string;
  techStack: string[];
}

const templates: Template[] = [
  {
    id: 1,
    title: "Ready Made Landing Page",
    description: "Book landing page - Modern landing page for Digital Marketer with elegant design, responsive layout, and engaging user interface.",
    imageUrl: "https://placehold.co/600x400/1e293b/e2e8f0?text=Landing+Page",
    demoUrl: "https://freebook.bnespace.com/",
    techStack: ["React", "Node.js", "MongoDB"]
  },
  {
    id: 2,
    title: "Pickup Line Generator",
    description: "Fun and interactive app that generates creative pickup lines. Perfect for social engagement and entertainment.",
    imageUrl: "https://placehold.co/600x400/1e293b/e2e8f0?text=PickupLine",
    demoUrl: "https://pickupmm.bnespace.com",
    techStack: ["React", "Node.js", "OpenAI"]
  },
  {
    id: 3,
    title: "SaaS Dashboard",
    description: "Professional dashboard with analytics, user management, and subscription handling.",
    imageUrl: "https://placehold.co/600x400/1e293b/e2e8f0?text=Dashboard",
    demoUrl: "#",
    techStack: ["React", "Node.js", "MongoDB"]
  },
  {
    id: 4,
    title: "AI Blog Platform",
    description: "Modern blog platform with AI-powered content suggestions and SEO optimization.",
    imageUrl: "https://placehold.co/600x400/1e293b/e2e8f0?text=Blog",
    demoUrl: "#",
    techStack: ["Next.js", "OpenAI", "PostgreSQL"]
  },
  {
    id: 5,
    title: "Social Network",
    description: "Feature-rich social platform with real-time chat and content sharing.",
    imageUrl: "https://placehold.co/600x400/1e293b/e2e8f0?text=Social",
    demoUrl: "#",
    techStack: ["React", "Socket.io", "Redis"]
  },
  {
    id: 6,
    title: "Learning Management",
    description: "Complete LMS with course creation, student progress tracking, and video lessons.",
    imageUrl: "https://placehold.co/600x400/1e293b/e2e8f0?text=LMS",
    demoUrl: "#",
    techStack: ["Vue.js", "Django", "AWS"]
  }
];

const TemplateCard: React.FC<{ template: Template }> = ({ template }) => {
  return (
    <div className="template-card">
      <div className="template-preview">
        <img src={template.imageUrl} alt={template.title} className="template-image" />
        <div className="template-overlay">
          <div className="template-actions">
            <a href={template.demoUrl} className="preview-btn" target="_blank" rel="noopener noreferrer">
              Live Demo
            </a>
            <button className="deploy-btn">
              Deploy Now
            </button>
          </div>
        </div>
      </div>
      <div className="template-info">
        <h3>{template.title}</h3>
        <p>{template.description}</p>
        <div className="tech-stack">
          {template.techStack.map((tech, index) => (
            <span key={index} className="tech-badge">
              {tech}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const TemplateShowcase: React.FC = () => {
  return (
    <section className="templates-section">
      <div className="templates-container">
        <div className="section-header">
          <h2>Ready-to-Deploy Templates</h2>
          <p>Launch your project faster with our professionally designed templates</p>
        </div>
        <div className="templates-grid">
          {templates.map((template) => (
            <TemplateCard key={template.id} template={template} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TemplateShowcase;
